.navbar{
    position: sticky;
    top: 0;
    
    @media (max-width: 576px){
      display:none
    }      
}
.mobile-navbar{
  display: none;
  background-color:#A4B99E;
  border-top-left-radius: 200px;
  border-bottom-left-radius: 200px; 
  align-items: end;
  justify-content: center;
  display: none;  
  height: 75px;  
  margin-top:5px;
  @media (max-width: 576px){
    display:block;
  } 
}

.box {
  background-image: "image.png";
      /* --mask:
        radial-gradient(214.66px at calc(100% - 288.00px) 50%,#000 99%,#0000 101%) 0 calc(50% - 192px)/100% 384px,
        radial-gradient(214.66px at calc(100% + 192.00px) 50%,#0000 99%,#000 101%) calc(100% - 96px) 50%/100% 384px repeat-y;
      -webkit-mask: var(--mask);
              mask: var(--mask); */
    /* Copy the CSS */
    /* By Temani Afif?twitter iconBuy Me A Coffee */
  }

.me-icon{
  max-height:200px;
  padding-top: 1rem;
  border-bottom-left-radius: 175px;
  border-bottom-right-radius: 175px;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 120%;
  transform: scaleX(0);
  height: 50%;
  bottom: 0;
  left: -3px;
  background-color: #FEFAE0;
  transform-origin: bottom right;
  transition: transform 0.4s ease-out;
  opacity: .2;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.bottom-nav{
  background-color:#A4B99E;
  border-top-left-radius: 200px;
  border-top-right-radius: 200px; 
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.top-nav{
  background-color:#A4B99E;
  border-bottom-left-radius: 200px;
  border-bottom-right-radius: 200px; 
  align-items: end;
  justify-content: center;
  display: flex;  
}