
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

body {
  background-color: #dbdabc;
  font-family: 'The Seasons';  
}
.App {
  /* background-color: #dbdabc; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #333329;
  grid-column: span 8 / span 8;
  @media (max-width: 576px){
    grid-column: span 12 / span 12;
    justify-content: start;
    margin: 1rem;
  } 
}

.App-link {
  color: #306D8B;
}
